import storefrontsJson from '$services/storefronts/storefronts.json'
import { Geolocate } from '$services/geolocate'
import type { JBGWeb } from '$types/JBGWeb'

export function getCurrencySymbol(currencyCode: string): string {
    switch (currencyCode) {
    case 'EUR':
        return '€'
    case 'GBP':
        return '£'
    case 'CAD':
    case 'MXN':
    case 'USD':
    default:
        return '$'
    }
}

// eslint-disable-next-line complexity
function getPlatformInfo(key: string): string[] | null {
    if (!key) return null

    let displayText = ''
    let iconFileName = ''
    switch (key) {
    case 'steam':
        displayText = 'Steam'
        iconFileName = 'steam'
        return [displayText, iconFileName]
    case 'google':
        displayText = 'Google Play'
        iconFileName = 'google'
        return [displayText, iconFileName]
    case 'humble':
        displayText = 'Humble Bundle'
        iconFileName = 'humble'
        return [displayText, iconFileName]
    case 'nintendo':
        displayText = 'Nintendo Switch'
        iconFileName = 'nintendo-switch'
        return [displayText, iconFileName]
    case 'microsoft':
        displayText = 'Xbox'
        iconFileName = 'xbox'
        return [displayText, iconFileName]
    case 'sony':
        displayText = 'Playstation'
        iconFileName = 'ps'
        return [displayText, iconFileName]
    case 'apple':
        displayText = 'Apple TV & iPad'
        iconFileName = 'apple'
        return [displayText, iconFileName]
    case 'apple-mac':
        displayText = 'Mac App Store'
        iconFileName = 'mas'
        return [displayText, iconFileName]
    case 'epic':
        displayText = 'Epic Games Store'
        iconFileName = 'egs'
        return [displayText, iconFileName]
    case 'amazon':
        displayText = 'Amazon Fire TV'
        iconFileName = 'amazon'
        return [displayText, iconFileName]
    case 'luna':
        displayText = 'Amazon Luna'
        iconFileName = 'luna'
        return [displayText, iconFileName]
    default:
        // TODO: fallbacks text and icon?
        return [displayText, iconFileName]
    }
}

export function getStorefrontsFromProductData(product: JBGWeb.ProductDetail) {
    if (!product) return

    const platforms: JBGWeb.PlatformOption[] = []
    const shopifyData: JBGWeb.ShopifyData = product.shopifyData
    if (!shopifyData.data.product) return
    const shopifyMetafieldsArr: JBGWeb.ProductMetafield[] = shopifyData.data.product.metafields

    if (!shopifyMetafieldsArr) return console.warn('found no metafields on product')

    // for packs this should always just be an array of 1
    let fields: JBGWeb.ProductMetafieldFields[] = []
    shopifyMetafieldsArr.forEach((metafieldsObj) => {
        if (!metafieldsObj) return console.warn('found no fields in metafields object')
        fields = metafieldsObj.reference.fields
    })

    fields.forEach((field: JBGWeb.ProductMetafieldFields) => {
        const platform: JBGWeb.PlatformOption = {
            text: null,
            icon: null,
            vendor: null,
            price: null,
            currencyCode: null
        }

        platform.vendor = field.key

        // destructure field.value
        // which comes in like this from shopify: "{\"amount\":\"29.99\",\"currency_code\":\"USD\"}"

        const json = JSON.parse(field.value)
        // do not include storefront when price is 0.0
        if (json.amount !== '0.0') {
            platform.price = Number(json.amount)
            platform.currencyCode = json.currency_code

            const platformInfo = getPlatformInfo(field.key)
            if (platformInfo) {
                platform.text = platformInfo[0]
                platform.icon = platformInfo[1]
            }

            platforms.push(platform)
        }
    })

    // sort platforms in order of sale priority (as determined by marketing)
    const sortingTemplate = ['nintendo', 'microsoft', 'sony', 'steam', 'apple', 'amazon', 'epic', 'humble']
    const sort = sortingTemplate.map((i) => platforms.find((j) => j.vendor === i))
    // not all packs will have a value for each entry in the sortingTemplate,
    // so remove all undefined entries.
    const sortedPlatforms = sort.filter((i) => i !== undefined)

    return sortedPlatforms
}

function getUID(platform: string, slug: string) {
    if (!storefrontsJson[platform].titles[slug]) return ''
    const id = storefrontsJson[platform].titles[slug].id
    if (!id) return ''
    return id
}

function getRegion() {
    let region = 'US' // default US
    const cloudfrontRegion = Geolocate.getCountryCode()
    const storefrontRegionList = ['US', 'GB', 'DE', 'FR', 'IT', 'ES', 'MX', 'BR']
    if (storefrontRegionList.includes(cloudfrontRegion)) {
        region = cloudfrontRegion
    }
    return region
}

function steamLocaleParam(region: string) {
    switch (region) {
    case 'DE':
        return '?l=german'
    case 'FR':
        return '?l=french'
    case 'IT':
        return '?l=italian'
    case 'ES':
        return '?l=spanish'
    case 'MX':
        return '?l=latam'
    case 'BR':
        return '?l=brazilian'
    default: return ''
    }
}

function epicLocaleParam(region: string) {
    switch (region) {
    case 'DE':
        return '?lang=de'
    case 'FR':
        return '?lang=fr'
    case 'IT':
        return '?lang=it'
    case 'ES':
        return '?lang=es-ES'
    case 'MX':
        return '?lang=es-MX'
    case 'BR':
        return '?lang=pt-BR'
    default: return ''
    }
}

function getBaseUrl(platform: string, region: string): string {
    const r = `${region}Url`
    return storefrontsJson[platform][r]
}

// eslint-disable-next-line complexity, max-len
export function getStorefrontLink(slug: string, platform: string, _fellBackToEn = false, isBundle = false, _isLocalized = false): URL | undefined {
    if (!platform || !slug) return

    const lookupSlug = slug
    // if (isLocalized) {
    //     if (I18n.locale !== 'en' && !fellBackToEn) {
    //         // remove localized slug endings; ex: '-de'
    //         lookupSlug = slug.slice(0, -(I18n.locale.length + 1))
    //     }
    // }

    const region = getRegion()
    const UID = getUID(platform, lookupSlug)
    const baseUrl = getBaseUrl(platform, region)
    let nonUIDRegions = [] // used in some cases to list what regions do not contain a UID in their url

    let url
    switch (platform) {
    case 'google':
        url = new URL(`${UID}`, baseUrl)
        break
    case 'humble':
        if (lookupSlug === 'drawful-2') {
            slug = 'drawful-two'
        }

        if (lookupSlug === 'the-jackbox-party-pack-1') {
            slug = 'the-jackbox-party-pack'
        }

        if (lookupSlug === 'fibbage') {
            slug = 'fibbage-xl'
        }

        url = new URL(slug, baseUrl)
        break
    case 'steam':
        url = new URL(`app/${UID}/${slug.replace(/-/g, '_')}`, baseUrl)
        // BUNDLES
        if (isBundle) {
            url = new URL(`bundle/${UID}/${slug.replace(/-/g, '_')}`, baseUrl)
        }
        nonUIDRegions = ['US', 'UK']
        if (!nonUIDRegions.includes(region)) {
            url = new URL(`app/${UID}/${slug.replace(/-/g, '_')}/${steamLocaleParam(region)}`, baseUrl)
            // BUNDLES
            if (isBundle) {
                url = new URL(`bundle/${UID}/${slug.replace(/-/g, '_')}/${steamLocaleParam(region)}`, baseUrl)
            }
        }
        break
    case 'nintendo':
        if (lookupSlug === 'the-jackbox-party-pack-1') {
            slug = 'the-jackbox-party-pack'
        }
        if (lookupSlug === 'quiplash-2-interlashional') {
            slug = 'quiplash-2-interlashional-the-say-anything-party-game'
        }
        if (lookupSlug === 'fibbage') {
            slug = 'fibbage-xl'
        }
        if (lookupSlug === 'the-jackbox-naughty-pack') {
            slug = slug.slice(4) // remove 'the-'
        }
        url = new URL(`${slug}-switch`, baseUrl)
        nonUIDRegions = ['US', 'MX', 'BR']
        if (!nonUIDRegions.includes(region)) {
            url = new URL(`${slug}-switch-${UID}.html`, baseUrl)
        }
        break
    case 'microsoft':
        if (lookupSlug === 'quiplash-2-interlashional') {
            slug = 'quiplash-2-interlashional-the-say-anything-party-game'
        }
        if (lookupSlug === 'fibbage') {
            slug = 'fibbage-the-hilarious-bluffing-party-game'
        }
        url = new URL(`${slug}/${UID}`, baseUrl)
        break
    case 'epic':
        if (lookupSlug.includes('the-')) {
            slug = slug.slice(4)
        }

        url = new URL(`${slug}${UID}`, baseUrl)
        nonUIDRegions = ['US', 'UK']
        if (!nonUIDRegions.includes(region)) {
            url = new URL(`${slug}${UID}/${(epicLocaleParam(region))}`, baseUrl)
        }
        break
    case 'sony':
        if (lookupSlug === 'the-jackbox-party-pack-10' || lookupSlug === 'the-jackbox-party-pack-9') {
            url = new URL(`concept/${UID}`, baseUrl)
        } else {
            url = new URL(`product/${UID}`, baseUrl)
        }
        break
    case 'amazon':
        url = new URL(`dp/${UID}`, baseUrl)
        if (lookupSlug === 'fibbage') {
            slug = 'Fibbage-Hilarious-Bluffing-Party-Game'
        }
        url = new URL(`${slug}/${UID}`, baseUrl)
        break
    case 'luna':
        url = new URL('https://www.amazon.com/luna/detail/B09R97C6ZG?ref_=jbx')
        break
    case 'apple':
        url = new URL(`${slug}/${UID}`, baseUrl)
        break
    case 'mas':
        url = new URL(`${slug}/${UID}`, baseUrl)
        break
    }
    return url.href
}

export function getShopifyLink(id: string) {
    const baseUrl = getBaseUrl('jbg', getRegion())
    return new URL(`${id}`, baseUrl).toString()
}

// returns a class that is used in GTM by marketing on elements that link to external storefront
export function getProductGTMClass(slug: string) {
    if (!slug) return
    // for most products we can just slice off 'the-jackbox-party-'
    let slicedSlug = slug.slice(18)

    // naughty pack
    if (slug === 'the-jackbox-naughty-pack') {
        slicedSlug = 'jnp'
    }

    // party starter
    if (slug === 'the-jackbox-party-starter') {
        slicedSlug = slug.slice(12)
    }

    // standalones
    if (
        slug === 'drawful-2'
        || slug === 'quiplash'
        || slug === 'fibbage'
        || slug === 'quiplash-2-interlashional'
    ) {
        slicedSlug = slug
    }

    return slicedSlug
}
